@use "sass:math";
@use "sass:string";
@use "../utilities/initial-variables" as iv;

.#{iv.$helpers-prefix}gapless {
  gap: 0 !important;
}

$gaps: "gap", "column-gap", "row-gap";
$gap-base: 0.5rem;

@each $gap in $gaps {
  @for $i from 0 through 8 {
    .#{iv.$helpers-prefix}#{$gap}-#{$i} {
      #{$gap}: ($gap-base * $i) !important;
    }

    @if $i < 8 {
      .#{iv.$helpers-prefix}#{$gap}-#{$i}\.5 {
        #{$gap}: ($gap-base * $i + math.div($gap-base, 2)) !important;
      }
    }
  }
}

@use "../utilities/initial-variables" as iv;
@use "../utilities/mixins" as mx;

.#{iv.$helpers-prefix}display-none,
.#{iv.$helpers-prefix}hidden {
  display: none !important;
}

$displays: block flex inline inline-block inline-flex grid;

@each $display in $displays {
  .#{iv.$helpers-prefix}display-#{$display},
  .#{iv.$helpers-prefix}#{$display} {
    display: $display !important;
  }

  @include mx.mobile {
    .#{iv.$helpers-prefix}display-#{$display}-mobile,
    .#{iv.$helpers-prefix}#{$display}-mobile {
      display: $display !important;
    }
  }

  @include mx.tablet {
    .#{iv.$helpers-prefix}display-#{$display}-tablet,
    .#{iv.$helpers-prefix}#{$display}-tablet {
      display: $display !important;
    }
  }

  @include mx.tablet-only {
    .#{iv.$helpers-prefix}display-#{$display}-tablet-only,
    .#{iv.$helpers-prefix}#{$display}-tablet-only {
      display: $display !important;
    }
  }

  @include mx.touch {
    .#{iv.$helpers-prefix}display-#{$display}-touch,
    .#{iv.$helpers-prefix}#{$display}-touch {
      display: $display !important;
    }
  }

  @include mx.desktop {
    .#{iv.$helpers-prefix}display-#{$display}-desktop,
    .#{iv.$helpers-prefix}#{$display}-desktop {
      display: $display !important;
    }
  }

  @include mx.desktop-only {
    .#{iv.$helpers-prefix}display-#{$display}-desktop-only,
    .#{iv.$helpers-prefix}#{$display}-desktop-only {
      display: $display !important;
    }
  }

  @include mx.widescreen {
    .#{iv.$helpers-prefix}display-#{$display}-widescreen,
    .#{iv.$helpers-prefix}#{$display}-widescreen {
      display: $display !important;
    }
  }

  @include mx.widescreen-only {
    .#{iv.$helpers-prefix}display-#{$display}-widescreen-only,
    .#{iv.$helpers-prefix}#{$display}-widescreen-only {
      display: $display !important;
    }
  }

  @include mx.fullhd {
    .#{iv.$helpers-prefix}display-#{$display}-fullhd,
    .#{iv.$helpers-prefix}#{$display}-fullhd {
      display: $display !important;
    }
  }
}

.#{iv.$helpers-prefix}sr-only {
  border: none !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 0.01em !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 0.01em !important;
}

@include mx.mobile {
  .#{iv.$helpers-prefix}display-none-mobile,
  .#{iv.$helpers-prefix}hidden-mobile {
    display: none !important;
  }
}

@include mx.tablet {
  .#{iv.$helpers-prefix}display-none-tablet,
  .#{iv.$helpers-prefix}hidden-tablet {
    display: none !important;
  }
}

@include mx.tablet-only {
  .#{iv.$helpers-prefix}display-none-tablet-only,
  .#{iv.$helpers-prefix}hidden-tablet-only {
    display: none !important;
  }
}

@include mx.touch {
  .#{iv.$helpers-prefix}display-none-touch,
  .#{iv.$helpers-prefix}hidden-touch {
    display: none !important;
  }
}

@include mx.desktop {
  .#{iv.$helpers-prefix}display-none-desktop,
  .#{iv.$helpers-prefix}hidden-desktop {
    display: none !important;
  }
}

@include mx.desktop-only {
  .#{iv.$helpers-prefix}display-none-desktop-only,
  .#{iv.$helpers-prefix}hidden-desktop-only {
    display: none !important;
  }
}

@include mx.widescreen {
  .#{iv.$helpers-prefix}display-none-widescreen,
  .#{iv.$helpers-prefix}hidden-widescreen {
    display: none !important;
  }
}

@include mx.widescreen-only {
  .#{iv.$helpers-prefix}display-none-widescreen-only,
  .#{iv.$helpers-prefix}hidden-widescreen-only {
    display: none !important;
  }
}

@include mx.fullhd {
  .#{iv.$helpers-prefix}display-none-fullhd,
  .#{iv.$helpers-prefix}hidden-fullhd {
    display: none !important;
  }
}

.#{iv.$helpers-prefix}visibility-hidden,
.#{iv.$helpers-prefix}invisible {
  visibility: hidden !important;
}

@include mx.mobile {
  .#{iv.$helpers-prefix}visibility-hidden-mobile,
  .#{iv.$helpers-prefix}invisible-mobile {
    visibility: hidden !important;
  }
}

@include mx.tablet {
  .#{iv.$helpers-prefix}visibility-hidden-tablet,
  .#{iv.$helpers-prefix}invisible-tablet {
    visibility: hidden !important;
  }
}

@include mx.tablet-only {
  .#{iv.$helpers-prefix}visibility-hidden-tablet-only,
  .#{iv.$helpers-prefix}invisible-tablet-only {
    visibility: hidden !important;
  }
}

@include mx.touch {
  .#{iv.$helpers-prefix}visibility-hidden-touch,
  .#{iv.$helpers-prefix}invisible-touch {
    visibility: hidden !important;
  }
}

@include mx.desktop {
  .#{iv.$helpers-prefix}visibility-hidden-desktop,
  .#{iv.$helpers-prefix}invisible-desktop {
    visibility: hidden !important;
  }
}

@include mx.desktop-only {
  .#{iv.$helpers-prefix}visibility-hidden-desktop-only,
  .#{iv.$helpers-prefix}invisible-desktop-only {
    visibility: hidden !important;
  }
}

@include mx.widescreen {
  .#{iv.$helpers-prefix}visibility-hidden-widescreen,
  .#{iv.$helpers-prefix}invisible-widescreen {
    visibility: hidden !important;
  }
}

@include mx.widescreen-only {
  .#{iv.$helpers-prefix}visibility-hidden-widescreen-only,
  .#{iv.$helpers-prefix}invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@include mx.fullhd {
  .#{iv.$helpers-prefix}visibility-hidden-fullhd,
  .#{iv.$helpers-prefix}invisible-fullhd {
    visibility: hidden !important;
  }
}

@use "../utilities/extends";
@use "../utilities/initial-variables" as iv;

.#{iv.$helpers-prefix}overlay,
.#{iv.$helpers-prefix}overlay {
  @extend %overlay;
}

.#{iv.$helpers-prefix}relative {
  position: relative !important;
}

$positions: absolute fixed relative static sticky;

@each $position in $positions {
  .#{iv.$helpers-prefix}position-#{$position} {
    position: $position !important;
  }
}

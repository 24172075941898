@use "../utilities/extends";
@use "../utilities/initial-variables" as iv;

.#{iv.$helpers-prefix}radiusless {
  border-radius: 0 !important;
}

.#{iv.$helpers-prefix}shadowless {
  box-shadow: none !important;
}

.#{iv.$helpers-prefix}clickable {
  cursor: pointer !important;
  pointer-events: all !important;
}

.#{iv.$helpers-prefix}unselectable {
  @extend %unselectable;
}
